<template>
  <div class="page-header-index-wide">

    <a-card :loading="loading" :bordered="false" :body-style="{padding: '0'}">
      <div class="salesCard">
        <a-tabs default-active-key="1" size="large" :tab-bar-style="{marginBottom: '24px', paddingLeft: '16px'}">
          <div class="extra-wrapper" slot="tabBarExtraContent">
            <div class="extra-item">
              <a-select
                v-model="appssid"
                style="width: 190px"
                @change="changeSelectAppssid"
              >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </div>

            <div class="extra-item">
              <a :class="{ 'time-select': true, active: activeIndex == timeType.day }" @click="changeDate('day')">今日</a>
              <a :class="{ 'time-select': true, active: activeIndex == timeType.week }" @click="changeDate('week')">本周</a>
              <a :class="{ 'time-select': true, active: activeIndex == timeType.month }" @click="changeDate('month')">本月</a>
              <a :class="{ 'time-select': true, active: activeIndex == timeType.year }" @click="changeDate('year')">本年</a>
            </div>
            <a-range-picker :style="{width: '256px'}" v-model="rangeDate" format="YYYY-MM-DD" @change="changeSelectDate" />
            &nbsp;&nbsp;
            <a-button type="primary" @click="queryResult" icon="search" :disabled="queryResultDisabled" >{{ $t('search') }}</a-button>
          </div>
          <a-tab-pane loading="true" tab="A-S转化漏斗" key="1">
            <a-row>
              <a-col>
                <div style="padding: 25px;">
<!--                    <h3 style="text-align: center; margin-bottom: 8px;">A-S总体转化率 <span style="font-size: 18px;">{{ converRateData.totalRate || 0 }}%</span></h3>-->
                    <div style="margin-bottom: 10px;">
                        <div style="overflow: hidden;">
                            <span class="fl"><span class="serial">1</span>注册</span>
                            <span class="fr">{{ converRateData.countRegister || 0 }}人</span>
                        </div>
                        <mini-progress color="rgb(19, 194, 194)" :target="100" :percentage="100" height="20px" />
                        <div style="text-align: center;">100%</div>
                    </div>
                  <div style="margin-bottom: 10px;">
                    <div style="overflow: hidden;">
                      <span class="fl"><span class="serial">2</span>工作信息完善</span>
                      <span class="fr">{{ converRateData.countCustWorkExt || 0 }}人</span>
                    </div>
                    <mini-progress color="rgb(19, 194, 194)" :target="converRateData.countCustWorkExtRate || 0" :percentage="converRateData.countCustWorkExtRate || 0" height="20px" />
                    <div style="text-align: center;">{{ converRateData.countCustWorkExtRate || 0 }}% / {{ converRateData.countCustWorkExtStepRate || 0 }}%</div>
                  </div>

                  <div style="margin-bottom: 10px;">
                    <div style="overflow: hidden;">
                      <span class="fl"><span class="serial">3</span>联系人完善</span>
                      <span class="fr">{{ converRateData.countCustLinkman || 0 }}人</span>
                    </div>
                    <mini-progress color="rgb(19, 194, 194)" :target="converRateData.countCustLinkmanRate || 0" :percentage="converRateData.countCustLinkmanRate || 0" height="20px" />
                    <div style="text-align: center;">{{ converRateData.countCustLinkmanRate || 0 }}% / {{ converRateData.countCustLinkmanStepRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <div style="overflow: hidden;">
                      <span class="fl"><span class="serial">4</span>JSON上传</span>
                      <span class="fr">{{ converRateData.countCustJSON || 0 }}人</span>
                    </div>
                    <mini-progress color="rgb(19, 194, 194)" :target="converRateData.countCustJSONRate || 0" :percentage="converRateData.countCustJSONRate || 0" height="20px" />
                    <div style="text-align: center;">{{ converRateData.countCustJSONRate || 0 }}% / {{ converRateData.countCustJSONStepRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <div style="overflow: hidden;">
                      <span class="fl"><span class="serial">5</span>身份证照片上传</span>
                      <span class="fr">{{ converRateData.countCustCurpImage || 0 }}人</span>
                    </div>
                    <mini-progress color="rgb(19, 194, 194)" :target="converRateData.countCustCurpImageRate || 0" :percentage="converRateData.countCustCurpImageRate || 0" height="20px" />
                    <div style="text-align: center;">{{ converRateData.countCustCurpImageRate || 0 }}% / {{ converRateData.countCustCurpImageStepRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <div style="overflow: hidden;">
                      <span class="fl"><span class="serial">6</span>人脸照片上传</span>
                      <span class="fr">{{ converRateData.countCustFaceImage || 0 }}人</span>
                    </div>
                    <mini-progress color="rgb(19, 194, 194)" :target="converRateData.countCustFaceImageRate || 0" :percentage="converRateData.countCustFaceImageRate || 0" height="20px" />
                    <div style="text-align: center;">{{ converRateData.countCustFaceImageRate || 0 }}% / {{ converRateData.countCustFaceImageStepRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <div style="overflow: hidden;">
                      <span class="fl"><span class="serial">7</span>基本信息完善</span>
                      <span class="fr">{{ converRateData.countCustExt || 0 }}人</span>
                    </div>
                    <mini-progress color="rgb(19, 194, 194)" :target="converRateData.countCustExtRate || 0" :percentage="converRateData.countCustExtRate || 0" height="20px" />
                    <div style="text-align: center;">{{ converRateData.countCustExtRate || 0 }}% / {{ converRateData.countCustExtStepRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <div style="overflow: hidden;">
                      <span class="fl"><span class="serial">8</span>银行卡添加</span>
                      <span class="fr">{{ converRateData.countCustBank || 0 }}人</span>
                    </div>
                    <mini-progress color="rgb(19, 194, 194)" :target="converRateData.countCustBankRate || 0" :percentage="converRateData.countCustBankRate || 0" height="20px" />
                    <div style="text-align: center;">{{ converRateData.countCustBankRate || 0 }}% / {{ converRateData.countCustBankStepRate || 0 }}%</div>
                  </div>
                    <div style="margin-bottom: 10px;">
                        <div style="overflow: hidden;">
                            <span class="fl"><span class="serial">9</span>提交订单</span>
                            <span class="fr">{{ converRateData.submitUser || 0 }}人</span>
                        </div>
                        <mini-progress color="rgb(19, 194, 194)" :target="converRateData.submitUserRate || 0" :percentage="converRateData.submitUserRate || 0" height="20px" />
                        <div style="text-align: center;">{{ converRateData.submitUserRate || 0 }}% / {{ converRateData.submitUserStepRate || 0 }}%</div>
                    </div>
                  <div style="margin-bottom: 10px;">
                    <div style="overflow: hidden;">
                      <span class="fl"><span class="serial">10</span>机审通过</span>
                      <span class="fr">{{ converRateData.machinePass || 0 }}人</span>
                    </div>
                    <mini-progress color="rgb(19, 194, 194)" :target="converRateData.machinePassRate || 0" :percentage="converRateData.machinePassRate || 0" height="20px" />
                    <div style="text-align: center;">{{ converRateData.machinePassRate || 0 }}% / {{ converRateData.machinePassStepRate || 0 }}%</div>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <div style="overflow: hidden;">
                      <span class="fl"><span class="serial">11</span>人工审批通过</span>
                      <span class="fr">{{ converRateData.accessUser || 0 }}人</span>
                    </div>
                    <mini-progress color="rgb(19, 194, 194)" :target="converRateData.accessUserRate || 0" :percentage="converRateData.accessUserRate || 0" height="20px" />
                    <div style="text-align: center;">{{ converRateData.accessUserRate || 0 }}% / {{ converRateData.accessUserStepRate || 0 }}%</div>
                  </div>
                    <div style="margin-bottom: 10px;">
                        <div style="overflow: hidden;">
                            <span class="fl"><span class="serial">12</span>放款</span>
                            <span class="fr">{{ converRateData.loanUser || 0 }}人</span>
                        </div>
                        <mini-progress color="rgb(19, 194, 194)" :target="converRateData.loanUserRate || 0" :percentage="converRateData.loanUserRate || 0" height="20px" />
                        <div style="text-align: center;">{{ converRateData.loanUserRate || 0 }}% / {{ converRateData.loanUserStepRate || 0 }}%</div>
                    </div>
                </div>
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import { ChartCard, MiniArea, MiniBar, MiniProgress, RankList, Bar, Trend, NumberInfo, MiniSmoothArea, Liquid, Line, Pie } from '@/components'
import { mixinDevice } from '@/utils/mixin'
import { mapActions, mapGetters } from 'vuex';
import {getApplist} from "@/api/order";

export default {
  name: 'Analysis',
  mixins: [mixinDevice],
  components: {
    ChartCard,
    MiniArea,
    MiniBar,
    MiniProgress,
    RankList,
    Bar,
    cLine: Line,
    cPie: Pie,
    Trend,
    NumberInfo,
    MiniSmoothArea,
    Liquid,
  },
  data () {
    return {
      appList: [],
      loading: true,
      timeType: {
        day: 1,
        week: 2,
        month: 3,
        year: 4,
      },
      rangeDate: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      beginDate: undefined,
      endDate: undefined,
      activeIndex: undefined,
      queryResultDisabled: false,
      appssid: '11',
      settleType: {
        'UN_SETTLE': '未结清订单',
        'EARLY_SETTLED': '提前结清订单',
        'SETTLED': '正常结清订单',
        'OVERDUE_SETTLED': '逾期结清订单',
        'EXTENSION_SETTLED': '展期结清订单',
        'OVERDUE_EXTENSION_SETTLED': '逾期展期结清订单',
      },
      queryParam: {
        appssid: '11',
        beginDate : '',
        endDate : '',
      },

    }
  },
  computed: {
      ...mapGetters('home', [
          'partConversionRate',
      ]),
      converRateData () {
        return this.partConversionRate || {};
      },
  },
  created () {
    this.loading = false;
    this.requestNoAction().then(res => {
    })
    getApplist().then(res => {
      this.appList = res.data || [];
    })
  },
  methods: {
    ...mapActions('home', [
        'getPartConversionRate',
    ]),
    transformDate (date) {
      return date.format('YYYY-MM-DD')
    },
    changeDate (type) {
      const { transformDate } = this;
      const currentDay = transformDate(moment())
      this.activeIndex = this.timeType[type];
      this.beginDate = transformDate(moment().startOf(type));
      this.endDate = transformDate(moment().endOf(type));
      if (moment(currentDay).isBefore(this.endDate)){
        this.endDate = currentDay;
      }
      const { beginDate, endDate } = this;

      this.queryResultDisabled = true;
      this.getPartConversionRate({ appssid: this.queryParam.appssid, beginDate, endDate });

      setTimeout (()=>{
        this.queryResultDisabled=false;
      },30000);

    },
    changeSelectDate ([beginDate, endDate]) {
      if(!beginDate || !endDate) return;
      this.activeIndex = undefined;
      this.queryParam.beginDate = this.transformDate(beginDate);
      this.queryParam.endDate = this.transformDate(endDate);
      //this.getPartConversionRate({ appssid: this.queryParam.appssid, beginDate: this.queryParam.beginDate, endDate: this.queryParam.endDate});
    },
    changeSelectAppssid (appssid) {
      if(!appssid) return;
      this.activeIndex = undefined;
      this.queryParam.appssid = appssid;
  //    this.getPartConversionRate({ appssid: this.queryParam.appssid, beginDate: this.queryParam.beginDate, endDate: this.queryParam.endDate});
    },
    queryResult () {

      if(this.queryParam.beginDate == '')
        this.queryParam.beginDate = this.transformDate(moment());
      if(this.queryParam.endDate == '')
        this.queryParam.endDate = this.transformDate(moment());

      this.queryResultDisabled = true;
      this.getPartConversionRate({ appssid: this.queryParam.appssid, beginDate: this.queryParam.beginDate, endDate: this.queryParam.endDate});

      setTimeout (()=>{
        this.queryResultDisabled=false;
      },30000);
    },
    refreshLendingCount () {
      // this.getLendingCountByDate();
    },
    refreshLoanStatusCount () {
      // this.getLoanStatusCount();
    },
    requestAllAction () {
      return Promise.all([
          this.getPartConversionRate(),
      ])
    },
    requestNoAction () {
      return Promise.all([

      ])
    }
  }
}
</script>

<style lang="less" scoped>
    .green {
        color: #52c41a;
    }

    .red {
        color: #f5222d
    }

    .time-select {
      color: #00000073;

      &.active {
        color: #1890ff;
      }
    }

    .fl {
        float: left;
    }

    .fr {
        float: right;
    }

    .serial {
        display: inline-block;
        height: 24px;
        width: 24px;
        background: #e5e5e5;
        text-align: center;
        line-height: 24px;
        border-radius: 3px;
    }

    .amount {
        float: left;
        font-size: 16px;

        .unit {
            font-size: 24px;
        }
    }

    .loan-amount {
        font-size: 14px;

        p {
            margin: 0;
            padding: 0;
        }

        .main-title {
            color: #1890ff;
        }

        .sub-title {
            color: #00000073;
        }

        .amount {
            float: left;
            font-size: 32px;

            .unit {
                font-size: 16px;
            }
        }

        .grow {
            float: left;
            margin-left: 25px;
            color: #00000073;
        }

        .gross {
            float: right;
            margin-top: -15px;
            border-left: 1px solid #e5e5e5;
            padding-left: 10px;

            .gross-item {
                margin-bottom: 5px;
                color: #00000073;

                .total {
                    font-size: 18px;
                    color: #000;
                }
            }
        }
    }

  .extra-wrapper {
    line-height: 55px;
    padding-right: 24px;

    .extra-item {
      display: inline-block;
      margin-right: 24px;

      a {
        margin-left: 24px;
      }
    }
  }

  .antd-pro-pages-dashboard-analysis-twoColLayout {
    position: relative;
    display: flex;
    display: block;
    flex-flow: row wrap;
  }

  .antd-pro-pages-dashboard-analysis-salesCard {
    height: calc(100% - 24px);
    /deep/ .ant-card-head {
      position: relative;
    }
  }

  .dashboard-analysis-iconGroup {
    i {
      margin-left: 16px;
      color: rgba(0,0,0,.45);
      cursor: pointer;
      transition: color .32s;
      color: black;
    }
  }
  .analysis-salesTypeRadio {
    position: absolute;
    right: 54px;
    bottom: 12px;
  }
</style>
